
import Http from './Http'

export default {
    async getQueuesStats() {
        return await Http.get(`/monitor/queue/stats`)
    },
    async getQueueJobs(version) {
        return await Http.get(`monitor/queue/jobs?version=${version}&all=Y`)
    },
    async getQueueSuccessJobs(version) {
        return await Http.get(`/monitor/queue/success?version=${version}&all=Y`)
    },
    async getQueueFailedJobs(version) {
        return await Http.get(`/monitor/queue/failed?version=${version}&all=Y`)
    },
    async deleteJobFromQueue(id, version) {
        return await Http.delete(`/monitor/queue/${id}?version=${version}`)
    },
    async getTenants() {
        return await Http.get(`/monitor/tenants`)
    },
    async getTenantApplications(tenant) {
        return await Http.get(`/monitor/tenant/application?tenant=${tenant}`)
    },
    async createTenantApplication(data) {
        return await Http.post(`/monitor/application`, data)
    },
    async updateTenantApplication(id, data) {
        return await Http.post(`/monitor/application/${id}`, data, {
            headers: {
                'Content-Type':'multipart/form-data'
            }
        })
    },
    async deleteTenantApplication(app_id) {
        return await Http.delete(`/monitor/application/${app_id}`)
    },
    async getApiErrors() {
        return await Http.get(`/monitor/api_errors?limit=1000`)
    },
    async getCronLogs() {
        return await Http.get(`/monitor/api_cron_logs?limit=1000`)
    },
    async getExceptions() {
        return await Http.get(`/monitor/api_exceptions?limit=1000`)
    },
    async getApiAlerts() {
        return await Http.get(`/monitor/api_alerts?limit=1000`)
    },
    async updateApiAlerts(id, data) {
        return await Http.patch(`/monitor/api_alerts/${id}`, data)
    },
    async getRbacObjectRoleAccess(id=''){
        return await Http.get(`/monitor/rbac_object_role_access?role_id=${id}`)
    },
    async getRbacRoleAccess(id=''){
        return await Http.get(`/monitor/rbac_role_access?role_id=${id}`)
    },
    async updateRbacObjectRoleAccess(data) {
        return await Http.patch(`/monitor/rbac_object_role_access`, data)
    },
    async updateRbacRoleAccess(data) {
        return await Http.patch(`/monitor/rbac_role_access`, data)
    },
    async getRbacRoles(){
        return await Http.get(`/monitor/rbac_role`)
    },
    async getRbacAccess(){
        return await Http.get(`/monitor/rbac_access`)
    },
    async updateRbacAccess(id,data) {
        return await Http.patch(`/monitor/rbac_access/${id}`, data)
    },
    async createRbacAccess(data) {
        return await Http.post(`/monitor/rbac_access`, data)
    },
    async deleteRbacAccess(id) {
        return await Http.delete(`/monitor/rbac_access/${id}`)
    },
    async getAppParams() {
        return await Http.get(`/monitor/app/app_param_keys`)
    },
    async createAppParams(data) {
        return await Http.post(`/monitor/app/addApp_param_keys`, data)
    },
    async deleteAppParams(key) {
        return await Http.delete(`/monitor/app/deleteApp_param_keys/${key}`)
    },
    async editAppParams(data) {
        return await Http.patch(`/monitor/app/updateApp_param_keys`, data);
    },

    //Social Levels
    async getSocialLevels() {
        return await Http.get(`/monitor/social/level`)
    },
    async addSocialLevel(data) {
        return await Http.post(`/monitor/social/level`, data)
    },
    async editSocialLevel(level, data) {
        return await Http.patch(`/monitor/social/level/${level}`, data)
    },
    async deleteSocialLevel(level) {
        return await Http.delete(`/monitor/social/level/${level}`)
    },


   //Social Xp
    async getSocialxp() {
        return await Http.get(`/monitor/social/xp`)
    },
    async createSocialXp(data) {
        return await Http.post(`/monitor/social/xp`, data)
    },
    async deleteSocialXP(id) {
        return await Http.delete(`/monitor/social/xp/${id}`)
    },
    async editSocialXp(id, data) {
        return await Http.patch(`/monitor/social/xp/${id}`, data)
    },


    //Social Milestone
    async getSocialMilestones() {
        return await Http.get(`/monitor/social/milestone`)
    },
    async addSocialMilestone(data) {
        return await Http.post(`/monitor/social/milestone`, data)
    },
    async editSocialMilestone(milestone, data) {
        return await Http.patch(`/monitor/social/milestone/${milestone}`, data)
    },
    async deleteSocialMilestone(id) {
        return await Http.delete(`/monitor/social/milestone/${id}`)
    },


    //Spot Tournament
    async getAllTournament(){
        return await Http.get(`/monitor/football/tournament`)
    },

    //Sport Data Tenant Setup
    async getAllTenants(data) {
        return await Http.get(`/monitor/football/tenant`, data)
    },
    async createTenantSetup(data) {
        return await Http.post(`/monitor/football/tenant`, data)
    },
    async deleteTenantSetup(id) {
        return await Http.delete(`/monitor/football/tenant/${id}`)
    },
    async updateTenantSetup(id, data) {
        return await Http.patch(`/monitor/football/tenant/${id}`, data)
    },


    //Sport Data Tenant Tournament
    async getAllTenantsTournament(data) {
        return await Http.get(`/monitor/football/tenant_tournament`, data)
    },
    async createTenantTournament(data) {
        return await Http.post(`/monitor/football/tenant_tournament`, data)
    },
    async updateTenantTournament(data) {
        return await Http.patch(`/monitor/football/tenant_tournament`, data)
    },
    async deleteTenantTournament(data) {
        try {
            const response = await Http.delete(`/monitor/football/tenant_tournament`, {
                params: data
            });
            return response;
        } catch (error) {
            console.error("Error in DELETE request:", error);
            throw error;
        }
    },

    async getTenantDashboards(){
        return await Http.get(`/monitor/tenant/dashboard`)
    },
    async createTenantDashboard(data) {
        return await Http.post(`/monitor/dashboard`, data)
    },
    async updateTenantDashboard(tenant, data) {
        return await Http.patch(`/monitor/dashboard/${tenant}`,data)
    },
    async deleteTenantDashboard(tenant) {
        return await Http.delete(`/monitor/dashboard/${tenant}`)
    },

    async verifyTenant(data) {
        return await Http.get(`/monitor/sport_data/verify?${data}`)
    },
    async updateVersion(data) {
        return await Http.patch(`/monitor/version`, data)
    },
    async updateSSL(data) {
        return await Http.patch(`/monitor/ssl_links`, data)
    },

    // SSL Links

    async getAllSllLinks() {
        return await Http.get(`/monitor/tenant/ssl_links`)
    },
    async createTenantSSL(data) {
        return await Http.post(`/monitor/tenant/ssl_links`, data)
    },
    async updateTenantSSL(id, data) {
        return await Http.patch(`/monitor/tenant/ssl_links/${id}`, data)
    },
    async deleteTenantSSL(id) {
        return await Http.delete(`/monitor/tenant/ssl_links/${id}`)
    },
    async getMonitorPing(params=""){
        return await Http.get(`/monitor/ping?${params}`)
    },

    
    async getDashboardDocumentations(){
        return await Http.get(`/monitor/dashboard_documentations`)
    },
    async createDashboardDocumentation(data) {
        return await Http.post(`/monitor/dashboard_documentation`, data)
    },
    async getRbacObjects(data) {
        return await Http.get(`/monitor/rbac_objects`, data)
    },
    async deleteDashboardDocumentation(data) {
        try {
            const response = await Http.delete(`/monitor/dashboard_documentation`, {
                params: data
            });
            return response;
        } catch (error) {
            console.error("Error in DELETE request:", error);
            throw error;
        }
    },

    //Dashboard Announcements
    async getAllDashboardMessages() {
        return await Http.get(`/monitor/dashboard_announcements`)
    },
    async createDashboardMessages(data) {
        return await Http.post(`/monitor/dashboard_announcements`, data, {
            headers: {
                'Content-Type':'multipart/form-data'
            }
        })
    },
    async deleteDashboardMessages(dashboard_version) {
        return await Http.delete(`/monitor/dashboard_announcements/${dashboard_version}`)
    },

    async getSportCompetitions(data){
        return await Http.get(`/monitor/sport_competitions`)
    },
    async updateCompetitionLogo(id,data){
        return await Http.patch(`/monitor/sport_competitions/${id}`, data)

    }




}